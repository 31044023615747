import React, { forwardRef } from 'react';
import ToolTip from '../utility/ToolTip';

// TODO: Handle types
function InputField(
    {
        id,
        type = 'text',
        placeholder = '',
        label = '',
        value,
        onChange = () => {},
        required = false,
        hasIcon = false,
        error = {},
        min,
        tooltip = '',
        styles = '',
    },
    ref
) {
    const inputLabel = required ? `${label}*` : label;
    const errorMessageClassNames = `text-xs text-red-500 ${
        error.message ? 'visible' : 'invisible'
    }`;

    const defaultStyles = `block w-full px-4 py-3 h-12 shadow appearance-none rounded-md border-2 
              border-opacity-60 text-base bg-gray-800 text-gray-50 placeholder-gray-500 
              focus:outline-none focus:ring-1 focus:ring-blue-200 focus:ring-opacity-60
              ${hasIcon ? 'pl-8' : ''} ${
        error.message ? 'border-red-500' : 'border-gray-800'
    }`;

    return (
        <div className="relative w-full">
            {tooltip.length > 0 && <ToolTip message={tooltip} />}
            {label && (
                <label
                    className="text-sm font-medium text-gray-700 mb-1"
                    htmlFor={id}
                >
                    {inputLabel}
                </label>
            )}
            <input
                id={id}
                name={id}
                ref={ref}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                min={min}
                className={styles ? styles : defaultStyles}
            />
            <label className={errorMessageClassNames}>{error.message}</label>
        </div>
    );
}

export default forwardRef(InputField);
