import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function ResultCard({ node, fallbackImage }) {
    const {
        headerImage,
        description,
        excerpt,
        date,
        timeToRead,
        slug,
        localHeaderImage,
    } = node;
    const title = node.title || node.slug;
    const blogImage = localHeaderImage || headerImage || fallbackImage;

    return (
        <Link to={`${slug}`}>
            <div
                className="flex flex-col bg-gray-900 border border-gray-800 shadow-md rounded-2xl"
                key={slug}
            >
                <div className="rounded-t-2xl overflow-hidden">
                    <GatsbyImage
                        placeholder="blurred"
                        formats="[AUTO, AVIF, WEBP]"
                        layout="fullWidth"
                        image={getImage(blogImage)}
                        objectFit="cover"
                        style={{ height: '100%' }}
                        imgStyle={{
                            borderRadius: '5px 5px 0 0',
                            height: '100%',
                            width: '100%',
                        }}
                        alt=""
                    />
                </div>
                <div className="flex flex-col py-4 px-8 h-full">
                    <h3 className="text-xl text-gray-100 h-full line-clamp-2">
                        {title}
                    </h3>
                    <p
                        className="mt-4 text-base lg:text-base text-gray-200 line-clamp-3"
                        dangerouslySetInnerHTML={{
                            __html: description || excerpt,
                        }}
                    />
                    <div className="mt-6 flex items-end h-full justify-between">
                        <p className="text-sm lg:text-sm mb-3 text-gray-300">
                            {date} | {timeToRead} mins read
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    );
}
