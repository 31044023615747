import React, { useState } from 'react';
import Fade from '../animations/Fade';
import useMediaQuery from '../../../utils/hooks/useMediaQuery';
import useHover from '../../../utils/hooks/useHover';

export default function ToolTip({ message }) {
    const [clicked, setClicked] = useState(false);
    const [hoverRef, isHovered] = useHover();
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    let timeout;

    const handleClick = () => {
        clearTimeout(timeout);
        setClicked(true);
        timeout = setTimeout(() => setClicked(false), 5000);
    };

    return (
        <div className="">
            <div
                className={`has-tooltip cursor-pointer absolute right-0 top-[25%] bg-white rounded-full w-4 h-4 flex justify-center align-bottom whitespace-nowrap`}
                onClick={handleClick}
                ref={hoverRef}
            >
                <span className="relative text-xs pointer-events-none">?</span>
            </div>
            <Fade toggle={clicked || isHovered}>
                <div>
                    <span
                        className={`
                            ${clicked ? 'visible z-50' : ''} 
                            ${isHovered ? 'visible z-50' : ''}
                            ${
                                !isDesktop
                                    ? 'fixed inset-x-0 mx-auto top-[50%]'
                                    : 'bottom-[110%] right-[-31%]'
                            } 
                            absolute text-base lg:text-base border-solid border border-white 
                            rounded shadow-lg bg-gray-800 text-white
                            max-w-[300px] md:max-w-sm p-2`}
                    >
                        {message}
                    </span>
                </div>
            </Fade>
        </div>
    );
}
