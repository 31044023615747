import React from 'react';

export default function SkeletonBlogCards() {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto sm:px-6 lg:px-16 max-w-7xl md:max-w-4xl lg:max-w-7xl">
            {[...Array(6)].map((i, index) => (
                <div
                    key={index}
                    className="relative  flex flex-col bg-light-grey shadow-md border border-light-grey rounded-2xl overflow-hidden"
                >
                    <div className="animate-pulse">
                        <div className="rounded-t-2xl w-full h-48 bg-slate-500"></div>
                        <div className="flex flex-col py-4 px-4 lg:px-8 h-60 bg-gray-700">
                            <h3 className="relative text-xl text-white bg-slate-500 w-full h-20 rounded"></h3>
                            <p className="relative mt-4 text-base text-gray-600 bg-slate-500 h-full rounded" />
                            <div className="mt-4 flex items-end justify-between gap-4">
                                <span className="h-10 w-1/2 rounded animate-pulse bg-slate-500"></span>
                                <p className="h-10 w-1/2 rounded text-xs lg:text-sm bg-slate-500"></p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
