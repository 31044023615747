import React from 'react';
import { graphql } from 'gatsby';

import SearchPosts from '../components/organisms/SearchPosts';
import BaseTemplate from '../components/templates/BaseLayout';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SubHeader from '../components/atoms/typography/SubHeader';
import SEO from '../components/atoms/utility/seo';
import Spacer from '../components/atoms/utility/Spacer';

function Blog({ data, location }) {
    const posts = data.allMdx.edges;
    const { localSearchBlog } = data;

    return (
        <BaseTemplate>
            <SEO
                title="Blog"
                keywords={[
                    'blog',
                    'stories',
                    'partners',
                    'network',
                    'digital nomads',
                    'app development',
                    'Fetch.ai',
                ]}
                description={`Discover new features of the Fetch.ai app, digital twins, 
                and the developer platform. Find up to date information and resources for 
                digital nomads from our experts and more.`}
            />
            <PrimaryHeader text="Become a Digital Nomad Today" />
            <SubHeader
                text={
                    <>
                        <span className="text-white">Learn and discover</span>{' '}
                        how to become a digital nomad, lifestyle tips, remote
                        work trends, and the best destination cities for travel.{' '}
                        <span className="text-white">Stay informed</span> on the
                        latest Fetch.ai app features, announcements, and more.{' '}
                    </>
                }
            />
            <SearchPosts
                posts={posts}
                localSearchBlog={localSearchBlog}
                location={location}
            />
            <Spacer/>
        </BaseTemplate>
    );
}

export default Blog;

export const pageQuery = graphql`
    query {
        localSearchBlog {
            index
            store
        }
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/blog/" } }
        ) {
            edges {
                node {
                    excerpt
                    timeToRead
                    fields {
                        slug
                    }
                    headerImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        slug
                        localHeaderImage {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        category
                    }
                }
            }
        }
    }
`;
