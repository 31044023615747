import React from 'react';

export default function BlogFilters({ activeFilter, setActiveFilter }) {
    const filters = [
        'All',
        'Digital Nomad',
        'Announcements',
        'Fetch.ai App',
        'Platform',
    ];

    const handleFilterChange = (value) => {
        setActiveFilter(value);
    };

    return (
        <div className="flex items-end justify-end w-full max-w-7xl md:max-w-4xl lg:max-w-7xl sm:px-6 lg:px-16">
            <ul className="hidden md:flex gap-2 md:gap-4 lg:gap-6">
                {filters.map((filter, index) => (
                    <li
                        key={index}
                        onClick={() => handleFilterChange(filter)}
                        className={`cursor-pointer ${
                            activeFilter === filter
                                ? 'underline'
                                : 'text-grey'
                        }`}
                    >
                        {filter}
                    </li>
                ))}
            </ul>
            <label className="md:hidden">
                <span className="text-white">Category:</span>
                <select
                    value={activeFilter}
                    onChange={(e) => handleFilterChange(e.target.value)}
                    className="bg-grey text-white border-grey rounded-xl bg-transparent mt-4 mx-2"
                >
                    {filters.map((filter, index) => (
                        <option key={index}>{filter}</option>
                    ))}
                </select>
            </label>
        </div>
    );
}
