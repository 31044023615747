import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function BlogCard({ node, fallbackImage }) {
    const {
        timeToRead,
        excerpt,
        frontmatter: { title, description, date, slug, localHeaderImage },
        headerImage,
    } = node;
    const postTitle = title || slug;
    const blogImage = localHeaderImage || headerImage || fallbackImage;

    return (
        <Link to={`${slug}`}>
            <div
                className="flex flex-col  bg-black text-white border hover:border-highlight shadow-md hover:shadow-lg rounded-2xl transition ease-linear"
                key={slug}
            >
                <div className="rounded-t-2xl overflow-hidden">
                    <GatsbyImage
                        placeholder="blurred"
                        formats="[AUTO, AVIF, WEBP]"
                        layout="fullWidth"
                        image={getImage(blogImage)}
                        objectFit="cover"
                        style={{ height: '100%' }}
                        imgStyle={{
                            borderRadius: '5px 5px 0 0',
                            height: '100%',
                            width: '100%',
                        }}
                        alt=""
                    />
                </div>
                <div className="flex flex-col py-4 px-4 lg:px-6 xl:px-8 h-full">
                    <h3 className="text-xl h-full line-clamp-2">{postTitle}</h3>
                    <p
                        className="mt-4 text-base lg:text-base line-clamp-3"
                        dangerouslySetInnerHTML={{
                            __html: description || excerpt,
                        }}
                    />
                    <div className="mt-6 flex items-end h-full justify-between">
                        <p className="text-sm lg:text-sm mb-3">
                            {date} | {timeToRead} mins read
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    );
}
